import { useEffect, useState } from "react";
import firebase from "firebase/app";
import { days, fullDays } from "./../../../../src/utils/constants/weekDays";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { createToast } from "@/utils/toaster/createToast";
import i18next from "i18next";

type LeaderboardItemTypes = {
  uid: string;
  attempts: number;
  userName: string;
  winningDate: any;
  score: number;
  winningDay: string;
};

export const useLeaderboardData = ({ currentUser }) => {
  const [leaderboardData, setLeaderboardData] =
    useState<LeaderboardItemTypes[]>();
  const localAttempts = localStorage.getItem("attemptCount");
  const [attemptCount, setAttemptCount] = useState<number>(0);
  const [success, setSuccess] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [firstClickTime, setFirstClickTime] = useState<number | null>(null);

  const date = new Date();
  const calenderDate = date.toDateString();
  const dateHour = date.getHours();
  const dateSlugBuilder = `${(calenderDate as  any).replaceAll(" ", "-")}-${dateHour}-${
    brand.event.eventCode
  }`.toLowerCase();

  const data = {
    all: leaderboardData,
    hasScore1337: leaderboardData?.filter((user) => user.score === 1337),
    honorableMentions: leaderboardData?.filter((user) => user.score !== 1337),
  };

  const getAttempsLeaderboard = async () => {
    firebase
      .firestore()
      .collection("1337winners")
      .doc(dateSlugBuilder || `fallback-${brand.event.eventCode}`)
      .collection("records")
      .limit(25)
      .onSnapshot((snapshot) => {
        const formattedData = snapshot.docs.map((doc) => {
          console.log(doc.data().winningDate.seconds);

          const today = new Date(doc.data().winningDate.seconds * 1000);
          const winningDay = fullDays[days[today.getDay()]];
          if (doc?.data()) {
            if (doc.id === `${currentUser.userName}_${currentUser.uid}`) {
              setAttemptCount(doc.data().attempts);
              setSuccess(true);
            }

            return {
              ...doc.data(),
              winningDay: winningDay,
            } as LeaderboardItemTypes;
          }
        });

        setLeaderboardData(
          formattedData.sort((a, b) => a.attempts - b.attempts) || []
        );
      });
  };

  useEffect(() => {
    console.log("updating", dateSlugBuilder, dateHour);

    getAttempsLeaderboard();
  }, [dateHour, dateSlugBuilder]);

  useEffect(() => {
    if (leaderboardData) {
      const currentUserHasScore1337 = leaderboardData.some(
        (user) => user.uid === currentUser.uid && user.score === 1337
      );

      if (currentUserHasScore1337) {
        setSuccess(true);
        localStorage.setItem("hasWon1337", "true");
      } else {
        setSuccess(false);
        localStorage.setItem("hasWon1337", "false");
      }
    }
  }, [leaderboardData]);

  const handleClick = async (key) => {
    if (key === "1" && firstClickTime) {
      return;
    }

    if (key === "2" && !firstClickTime) {
      return;
    }

    if (firstClickTime === null) {
      setFirstClickTime(Date.now());
    } else {
      const secondClickTime = Date.now();
      const timeDifference = secondClickTime - firstClickTime;
      setCurrentTime(timeDifference); // Update currentTime to match timeDifference
      setAttemptCount((prevCount) => prevCount + 1);

      // Move inside handleClick function to use timeDifference
      const setCurrentUserInLeaderboard = async () => {
        const date = new Date();
        const calenderDate = date.toDateString();
        const dateHour = date.getHours();
        const dateSlugBuilder = `${(calenderDate as any).replaceAll(
          " ",
          "-"
        )}-${dateHour}-${brand.event.eventCode}`.toLowerCase();

        const data = {
          userName: currentUser.userName,
          uid: currentUser.uid,
          winningDate: firebase.firestore.FieldValue.serverTimestamp(),
          attempts: attemptCount + 1, // +1 because the setAttemptsCounts does not update attemptsCounts in this part of code at this time
          score: timeDifference,
        };

        await firebase
          .firestore()
          .collection("1337winners")
          .doc(dateSlugBuilder || `fallback-${brand.event.eventCode}`)
          .collection("records")
          .doc(`4d5${currentUser.userName}_${currentUser.uid}`)
          .set(data);

        createToast({
          type: "success",
          message: `${i18next.t("leet.toast.success")}.`,
        });
      };

      // Check if timeDifference is between 1300 and 1400
      setCurrentUserInLeaderboard();
      if (timeDifference >= 1331 && timeDifference <= 1341) {
      }
      // Check if timeDifference is exactly 1337
      if (timeDifference === 1337) {
        setSuccess(true);
        localStorage.setItem("hasWon1337", "true");
      }

      setFirstClickTime(null);
    }
  };

  return {
    data,
    attemptCount,
    success,
    setAttemptCount,
    setSuccess,
    handleClick,
    currentTime,
    setCurrentTime,
    firstClickTime,
  } as const;
};
