import { Button, Spinner } from "@/components";
import UnAuthorizedLayout from "../../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { FC, useEffect, useState } from "react";
import { AuthCard } from "./../components/AuthCard";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import {
  AuthenticationProps,
  AuthenticationResponse,
} from "../types/authenticationTypes";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { createToast } from "@/utils/toaster/createToast";
import { useNavigate } from "react-router";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "@/utils/constants/stringProcessing";
import { auth } from "@/api/firebase/firebase.config";
import { avatarsRaw } from "@/features/auth/utils/avatars";
import QueryDB from "@/api/queryDB/common";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Profile } from "@/models/database/profiles/profile.model";
import { useAuthPersistState } from "@/store/auth/auth.store";
import {
  collectionDoc,
  collectionRef,
} from "@/api/collectionRef/collectionOperations";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import i18next from "i18next";
import firebase from "firebase/app";
import { useAtomValue } from "jotai";
import { authCredentialsAtom } from "@/utils/atoms/atomClient";
import { validateName } from "../helperFunctions/validateName";

export const CreateUsernameAndProfile: FC<{}> = () => {
  const [userName, setUsername] = useState<string>("");
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const setUser = useAuthPersistState((state) => state.setUser);
  const navigate = useNavigate();
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );
  const atomCredentials = useAtomValue(authCredentialsAtom);
  const baseAuthenticationProps = {
    method: "email",
    emailAddress: localStorage.getItem("authEmailLocal"),
    platform: brand.brandValue,
    action: "verify-email",
  } as AuthenticationProps;

  const handleCreateUser = async () => {
    // Logic for if user logs in through google and does not have a complete account
    if (localStorage.getItem("signUpMethod") === "google") {
      const profileRef = await collectionDoc.profiles(auth.currentUser.uid);
      const profile = profileRef.data();

      if (profile?.userName) {
        return navigate("/dashboard");
      }

      const slug = normalizeString(
        convertCleanEntityNameToSlug(
          cleanEntityName(userName.trim().replace(/\s/g, ""))
        )
      );

      const checkIfTaken = await validateName(slug, "Username");

      if (checkIfTaken !== null) {
        return createToast({
          type: "error",
          message: checkIfTaken || "Something went wrong while updating",
        });
      }

      const userData = {
        slug,
        region: "Europe",
        userName: userName.trim().replace(/\s/g, ""),
        uid: auth.currentUser.uid,
        fullName: "",
        profileDescription: "",
        favoriteGames: [],
        lang: brand.defaultLanguage,
        emailAddress: localStorage.getItem("authEmailLocal"),
        email: localStorage.getItem("authEmailLocal"),
        profileLogoUrl:
          avatarsRaw[Math.floor(Math.random() * avatarsRaw.length)],
        theme: brand.brandTheme,
        platform: brand.brandValue,
        allPlatforms: [brand.brandValue],
        country: "",
        followersCount: 0,
        friendsCount: 0,
        profileLogoUrlHistory: [],
        termsAccepted: false,
        isTestAccount: window.location.pathname.includes("localhost:"),
      } as Profile;

      await QueryDB.createNewDoc("profiles", userData, auth.currentUser.uid);

      setProfile(userData);
      setUser(auth.currentUser);
      navigate("/dashboard");
      localStorage.removeItem("authEmailLocal");
      localStorage.removeItem("signUpMethod");

      return;
    } else {
      await authenticate({
        ...baseAuthenticationProps,
        action: "validate-username",
        userName: userName,
      });
    }
  };

  useEffect(() => {
    if (response && localStorage.getItem("signUpMethod") !== "google") {
      if (
        response.status === "username-invalid" ||
        response.status === "user-exists"
      ) {
        createToast({
          type: "error",
          message:
            response.status === "user-exists"
              ? "There is already a username connected to your email address!"
              : response.payload,
        });

        if (response.status === "user-exists") {
          localStorage.removeItem("authEmailLocal");
          navigate("/login");
        }

        return;
      }

      if (response.status === "not-found") {
        createToast({
          type: "error",
          message: "There is no account with your email!",
        });
        return;
      }

      if (response.status === "valid-username") {
        createToast({
          type: "success",
          message: "Profile created, please log in complete!",
        });
        localStorage.setItem("resetLang", "true");
        localStorage.removeItem("authEmailLocal");
        return navigate(response.redirectURL);
      }
    }
  }, [response]);

  // Page Document Title
  useDocumentTitle(i18next.t("auth.create.profile.headline"));

  return (
    <UnAuthorizedLayout>
      <div className="authentication__signup">
        <div className="signup">
          <AuthCard title={i18next.t("auth.create.profile.headline")} type="">
            {/* Username */}
            <div className="input">
              <BaseTextInput
                label={i18next.t("auth.create.profile.input.username.label")}
                textType="text"
                value={userName.trim().replace(/\s/g, "")}
                placeholder={i18next.t(
                  "auth.create.profile.input.username.placeholder"
                )}
                setSingleState={setUsername}
                error={undefined}
                functionOnEnter={async () => {
                  if (!_loading) {
                    handleCreateUser();
                  }
                }}
              />
            </div>
            <div className="signup__actions">
              <Button
                variant="primary"
                disabled={_loading}
                onClickAction={async (e) => {
                  handleCreateUser();
                }}
              >
                {_loading ? <Spinner /> : i18next.t("auth.input.button.create")}
              </Button>
            </div>
          </AuthCard>
        </div>
      </div>
    </UnAuthorizedLayout>
  );
};
