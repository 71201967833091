import { collectionDoc } from "@/api/collectionRef/collectionOperations";
import { auth } from "@/api/firebase/firebase.config";
import { Spinner } from "@/components/Spinner";
import { useAuthPersistState } from "@/store/auth/auth.store";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Login } from "@/features/auth";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import {
  createQuickAccountUser,
  useConnectWithTokenAndRedirect,
} from "@/utils/sharedHelperFunctions";
import i18n from "../../tranlations/i18n";
import { partnerRoute } from "../../../branchAndBrandSpecific/utils/brandVariables/siteRoutes/partnerRoutes";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useSetAtom } from "jotai";

const ProtectedRoutesAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthPersistState((state) => state.user);
  const setUser = useAuthPersistState((state) => state.setUser);
  const [error, setError] = useState<string | null>(null);
  const setProfile = useCurrentUserProfile((s) => s.setUser);

  const profile = useCurrentUserProfile((s) => s.user);
  const isUrlEventCode = window.location.pathname.includes("event-qr-code");
  const location = useLocation();
  const navigate = useNavigate();
  const setCurrentClient = useSetAtom(clientAtom);

  useEffect(() => {
    if (brand.brandTheme !== "wog") {
      document.documentElement.className = `theme-${brand.brandTheme}`;
    }
  }, [brand.brandTheme]);

  // Set different themes for individual partner pages
  useEffect(() => {
    if (brand.brandTheme === "elgiganten") {
      if (partnerRoute[location.pathname]) {
        document.documentElement.className =
          partnerRoute[location.pathname].theme;
      } else {
        if (!location.pathname.includes("/challenges/")) {
          document.documentElement.className = `theme-elgiganten`;
          setCurrentClient("");
        }
      }
    }
  }, [brand.brandTheme, location]);

  // When creating a user their profile language is set to brand.defaultLanguage
  // this way profiles wil defaul to "dan", but can still be swithed to eng in settings

  useEffect(() => {
    if (brand.defaultLanguage !== "") {
      i18n.changeLanguage(profile?.lang || brand?.defaultLanguage);
    }
  }, [brand.defaultLanguage, profile]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (!user) {
      setIsLoading(true);
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        // console.log("step 1");

        if (user) {
          // console.log("step 2 USER");

          if (!profile) {
            // console.log("step 3 NO PROFILE");

            if (isUrlEventCode && brand.eventHasAutoQrSignup) {
              //This code is for platforms that should auto login when going to any url of the event site
              // console.log("step 4 CREATE USER");

              await createQuickAccountUser(setProfile);
              setIsLoading(false);
            } else {
              (async () => {
                const profileRef = await collectionDoc.profiles(user.uid);
                const profile = profileRef.data();
                if (profile) {
                  setProfile(profile);
                }
              })();
            }
          }

          setUser(user);
        } else {
          if (isUrlEventCode) {
            // console.log("step 6 PROFILE");

            await createQuickAccountUser(setProfile);
          } else {
            // in case user log out
            setProfile(null);
            setUser(null);
          }
        }
      } catch (error: any) {
        setError(error.message);
      }

      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const path =
      location.pathname.split("/")[1].charAt(0).toUpperCase() +
      location.pathname.split("/")[1].slice(1);
    // document.title = `${path ? `${path} | ` : ""} ${brand.name}`;

    const [firstPartUrl, secondPartUrl] = location.pathname
      .split("/")
      .slice(1, 3);
    if (firstPartUrl !== "signincallback") {
      return;
    }
    if (firstPartUrl === "signincallback") {
      useConnectWithTokenAndRedirect(secondPartUrl);
    } else {
      const viewInUrl = firstPartUrl === "" ? "home" : firstPartUrl;
      const route = siteRoutes[viewInUrl];

      if (user) {
        if (route) {
          navigate(route);
        }
        navigate("/");
      } else {
        navigate("/landing");
      }
    }
  }, [location]);

  if (isLoading && !user) {
    return <Spinner />;
  }

  if (!!error) {
    throw Error(error);
  }

  // Auth routes that are or are not accisble when user is or is not logged in

  const authRoutes = {
    "/landing": "/landing",
    "/privacy-policy": `${siteRoutes["privacy-policy"]}`,
    "/terms": `${siteRoutes["terms"]}`,
    "/": "/",
    "/enter-username": "/enter-username",
    "/forgot-password": "/forgot-password",
    "/verify-email": "/verify-email",
    "/signup": "/signup",
    "/password-reset": "/password-reset",
    "/login": "/login",
    "/space-coins-redirect": "/space-coins-redirect",
    "/1337-redirect": "/1337-redirect",
    "/discord-auth": "/discord-auth",
    "/twitch-auth": "/twitch-auth",
  };

  if (
    brand.event.isEvent &&
    brand.hasFeature.qrSignUp &&
    (window.location.pathname.includes("/scan-qr/") ||
      window.location.pathname.includes("/treasure-hunt-redirect/") ||
      window.location.pathname.includes("/signup/")  || window.location.pathname.includes("/1337-challenge-vertical"))
  ) {
    return <Outlet />;
  }

  return (
    <>
      {/* When no user and url is not landing or empty */}
      {user === null && !authRoutes[window.location.pathname] && <Login />}

      {user === null && authRoutes[window.location.pathname] && <Outlet />}

      {/* When user and url is not landing */}
      {!!user && user !== null && <Outlet />}
    </>
  );
};

export default ProtectedRoutesAuth;
