import { useLeaderboardData } from "./helperFunctions/useLeaderboardData";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Challenge1337PageLeaderboard } from "./components/1337.challenge.page.leaderboard";
import { Challenge1337PageLeaderboardMentions } from "./components/1337.challenge.page.leaderboard.mentions";
import { ReactComponent as IconElgiganten } from "../../../branchAndBrandSpecific/utils/brandVariables/assets/elgiganten/elgiganten.svg";
import QRCode from "react-qr-code";
import { ReactComponent as IconScanCode } from "../../../branchAndBrandSpecific/features/1337-challenge/assets/scanThisCodeVertical.svg";
import QRCodeScan from "./assets/qr-code_signup.svg";
import React from "react";

export const Challenge1337PageVertical = () => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  const { data, attemptCount, success } = useLeaderboardData({ currentUser });

  return (
    <div className="leet-challenge leet-challenge-vertical">
      <section className="leet-challenge-vertical__heading">
        <IconElgiganten />
      </section>

      <div className="leet-challenge-vertical__leaderboard">
        <section className="leet-challenge__leaderboard">
          <h1>Stop the timer at 1337</h1>
          <Challenge1337PageLeaderboard data={data?.hasScore1337} />
        </section>

        <div className="leet-challenge-vertical__qr">
          <div className="leet-challenge-vertical__code">
            <img src={QRCodeScan} alt="QR Code" width={175} height={175} />
          </div>

          <div className="leet-challenge-vertical__scan-here">
            <IconScanCode />
          </div>
        </div>

        <section className="leet-challenge__mentions">
          <Challenge1337PageLeaderboardMentions
            data={data?.honorableMentions}
            hasWinners={data?.hasScore1337?.length === 0 ? false : true}
          />
        </section>
      </div>
    </div>
  );
};
