import { checkEntityNameValidity } from "@/utils/constants/stringProcessing";
import { validateNotEmpty } from "./validateNotEmpty";
import QueryDB from "@/api/queryDB/common";

/**
 * @slug user slug
 * @title username
 */
export const validateName = async (
  slug: string,
  title: string
): Promise<string | null | undefined> => {
  const isEmpty = validateNotEmpty(slug);
  if (isEmpty) return isEmpty;

  const isCorrect = validateNameIsCorrect(slug, title);
  if (isCorrect) return isCorrect;

  const isSlugTaken = await QueryDB.validateSlug("profiles", slug);
  if (isSlugTaken) return `This ${title.toLowerCase()} is already taken`;

  return null;
};

const validateNameIsCorrect = (value: string, title: string): string | null => {
  if (value.length > 0 && !checkEntityNameValidity(value)) {
    return `${title} should contain only letters and numbers`;
  }
  return null;
};
