import React, { FC, useEffect, useState } from "react";
import useDocumentTitle from "../../../src/hooks/useDocumentTitle";
import { Button } from "@/components";
import firebase from "firebase/app";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { days, fullDays } from "@/utils/constants/weekDays";
import { createToast } from "@/utils/toaster/createToast";
import i18next from "i18next";
import LeaderboardUserItem from "./components/LeaderboardItem/index";
import { schedule1337 } from "./1337Schedule";
import { useLeaderboardData } from "./helperFunctions/useLeaderboardData";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

type LeaderboardItemTypes = {
  uid: string;
  attempts: number;
  userName: string;
  winningDate: any;
  score: number;
  winningDay: string;
};

const Challenge1337Page: FC = (): JSX.Element => {
  useDocumentTitle("1337 Challenge");
  const isLargeVersion = window.location.pathname === "/1337-challenge-lg";
  const currentUser = useCurrentUserProfile((s) => s.user);

  const {
    data: leaderboardData,
    attemptCount,
    success,
    setAttemptCount,
    setSuccess,
    handleClick,
    currentTime,
    setCurrentTime,
    firstClickTime,
  } = useLeaderboardData({ currentUser });

  // TODO: Refactor schedule logic
  // Schedule related things
  const today2 = new Date();
  const currentDay2 = fullDays[days[today2.getDay()]];

  const getCurrentSchedule = () => {
    const today = new Date();
    const currentDay = fullDays[days[today.getDay()]];
    const currentHour = today.getHours();

    const currentSchedule = schedule1337.find(
      (item) =>
        item.day === currentDay &&
        currentHour >= item.startHour &&
        currentHour < item.endHour
    );

    return currentSchedule;
  };

  const currentRunningSchedule = getCurrentSchedule();
  const [startHour, setStartHour] = useState<number>(
    currentRunningSchedule?.startHour
  );
  const [endHour, setEndHour] = useState<number>(
    currentRunningSchedule?.endHour
  );
  useEffect(() => {
    const updateSchedule = () => {
      const newSchedule = getCurrentSchedule();
      if (newSchedule) {
        setStartHour(newSchedule.startHour);
        setEndHour(newSchedule.endHour);
      } else {
        setStartHour(null);
        setEndHour(null);
      }
    };

    // Check and update the schedule immediately
    updateSchedule();

    // Set interval to check the schedule every minute
    const intervalId = setInterval(updateSchedule, 15000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  //

  const filterByHour = (data: LeaderboardItemTypes[]) => {
    return data?.filter((user) => {
      const winningDate = new Date(user.winningDate.seconds * 1000);
      const winningHour = winningDate.getHours();

      return (
        winningHour >= startHour &&
        winningHour <= endHour &&
        user.winningDay === currentRunningSchedule?.day
      );
    });
  };

  const getDate = (seconds) => {
    if (!seconds) {
      return "---";
    }
    const date = new Date(seconds * 1000);
    const dateNo = date.getDay().toString();

    const time = `${String(date.getHours()).padStart(2, "0")}:${String(
      date.getMinutes()
    ).padStart(2, "0")}`;

    const formattedTime = `${i18next.t(`days.${dateNo}`)}, ${time}`;

    return formattedTime;
  };
  // ------------------

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (firstClickTime !== null) {
      interval = setInterval(() => {
        setCurrentTime(Date.now() - firstClickTime);
      }, 10);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [firstClickTime]);

  useEffect(() => {
    localStorage.setItem("attemptCount", attemptCount.toFixed(0));
  }, [attemptCount]);

  const hasScore1337 = leaderboardData?.hasScore1337?.slice(0, 11);
  const honorableMentions = leaderboardData?.honorableMentions?.slice(0, 30);

  return (
    <div
      id="leet"
      className={`leet ${isLargeVersion ? "leet-lg" : ""}`}
      onKeyDown={(e) => {
        if (e.key === "1") {
          handleClick(e.key);
        } else if (e.key === "2") {
          handleClick(e.key);
        }
      }}
      tabIndex={0}
    >
      <div className="leet-challenge">
        <div className="leet-challenge__heading">
          <h1>{i18next.t("leet.headline")}</h1>
        </div>
        <div className="leet-challenge__description">
          <p>{i18next.t("leet.objective", { objective: "1337" })}</p>
        </div>

        <div className="leet-challenge__grid">
          <div>
            <div className="leet-challenge__time">
              {!success && (
                <>
                  {firstClickTime ? (
                    <div className="leet-challenge__actualTime">
                      <p>{currentTime}</p>
                    </div>
                  ) : (
                    <div className="leet-challenge__actualTime">
                      <p>{currentTime}</p>
                    </div>
                  )}
                </>
              )}

              {success && (
                <div className="leet-challenge__actualTime leet-challenge__actualTime--success">
                  <p>1337</p>
                </div>
              )}
            </div>

            <div className="leet-challenge__actions">
              {!success && (
                <Button
                  variant="primary"
                  disabled={success}
                  onClickAction={handleClick}
                >
                  {!firstClickTime ? (
                    <>
                      {success
                        ? i18next.t("leet.youWon")
                        : i18next.t("leet.start")}
                    </>
                  ) : (
                    <>{i18next.t("leet.stop")}</>
                  )}
                </Button>
              )}
            </div>
            {success && (
              <div className="leet-challenge__success">
                <p>{i18next.t("leet.congrats")} </p>
              </div>
            )}
            <div className="leet-challenge__attempts">
              <p>
                {i18next.t("leet.totalAttempts")}: {attemptCount}
              </p>
            </div>
          </div>

          {!isLargeVersion && (
            <div className="leet-challenge__leaderboard">
              <h2>{i18next.t("leet.leaderboard.headline")}</h2>
              <div className="leaderboard">
                <div className="leaderboard__header">
                  <div>{i18next.t("leet.leaderboard.username")}</div>
                  <div>{i18next.t("leet.leaderboard.attempts")}</div>
                </div>
                {hasScore1337?.length === 0 && (
                  <div className="leaderboard__empty">
                    {i18next.t("leet.honorable.noWinners")}
                  </div>
                )}
                {hasScore1337 &&
                  hasScore1337.map((doc, index) => {
                    return (
                      <div className="leaderboard__item" key={index}>
                        <div className="item">
                          <div className="item__user">
                            <LeaderboardUserItem uid={doc.uid} />
                          </div>
                          <div className="item__score">{doc.attempts}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          <div className="leet-challenge__honorable">
            <div className="honorable">
              <h2>{i18next.t("leet.honorable.headline")}</h2>
              <div className="honorable__list">
                {honorableMentions?.length === 0 && (
                  <div className="honorable__item">
                    {i18next.t("leet.honorable.none")}
                    {hasScore1337?.length !== 0 &&
                      i18next.t("leet.honorable.winnersOnly")}
                  </div>
                )}
                {honorableMentions &&
                  honorableMentions.map((doc, index) => {
                    return (
                      <div className="honorable__item" key={index}>
                        {doc.userName} ({doc.score})
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenge1337Page;
